import { inject, Injectable } from '@angular/core';

import { tap } from 'rxjs';

import {
  About,
  AllRolesGQL,
  GetAboutCoverAndStoryQueryGQL,
  GetDepartmentMembersGQL,
  GetFullAboutGQL,
} from '@alan-apps/data-access';
import {
  BaseHttpService,
  cache,
  DocumentHeaderService,
  ICache,
} from '@nghedgehog/angular-ui';

import { constants } from '../../../environments/constants';

@Injectable({
  providedIn: 'root',
})
export class AboutService implements ICache {
  private header = inject(DocumentHeaderService);
  readonly storageKey = 'AboutService';

  constructor(
    private _http: BaseHttpService,
    private getFullAboutGQL: GetFullAboutGQL,
    private getAboutCoverAndStoryQueryGQL: GetAboutCoverAndStoryQueryGQL,
    private getDepartmentMembersGQL: GetDepartmentMembersGQL,
    private allRolesGQL: AllRolesGQL,
  ) {}

  homeData() {
    return this.getFullAbout().pipe(
      tap((x) => {
        try {
          this.header.setImage(x.info.logo.path.default);
          this.header.setInfo(
            { title: x.info.title, description: x.info.description },
            { override: true },
          );
        } catch (error) {
          console.log(error);
        }

        constants.rootData = x as About;
      }),
    );
  }

  @cache()
  private getFullAbout() {
    return this._http.apollo(this.getFullAboutGQL.fetch());
  }

  getAboutCoverAndStory() {
    return this._http.apollo(this.getAboutCoverAndStoryQueryGQL.fetch());
  }

  getDepartmentMembers(id: string) {
    return this._http.apollo(this.getDepartmentMembersGQL.fetch({ id }));
  }

  allRoleUsers(id?: string) {
    return this._http.apollo(
      this.allRolesGQL.fetch({
        department: id,
        withUser: true,
        withProfile: true,
      }),
    );
  }
}
